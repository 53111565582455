<template>
  <CContainer fluid class="mainTableContainer">
    <CRow alignVertical="center" alignHorizontal="center">
      <CCol lg="12" class="mainTableColumn">
        <CSelect
            label="Hotel"
            :options="selectHotelList"
            @update:value="changeHotel"
        ></CSelect>
        <CDataTable
            :noItemsView="{
            noResults: 'no items found',
            noItems: 'no items',
          }"
            :hover="true"
            :striped="true"
            :items-per-page="20"
            columnFilter
            :items="categories"
            sorter
            pagination
            :clickableRows="true"
            :fields="fields"
            size="sm"
        >
          <template #hotel="{ item }">
            <td>
              {{ getHotelNameByID(item.hotel_id) }}
            </td>
          </template>
          <template #user="{ item }">
            <td>
              {{ getUserNameByID(item.user_id) }}
            </td>
          </template>
          <template #updated_at="{ item }">
            <td>
              <Lock
                  :size="20"
                  @click.stop="disableSnapshot(item)"
                  v-if="item.locked"
              ></Lock>
              <LockOpen
                  :size="20"
                  @click.stop="disableSnapshot(item)"
                  v-else
              ></LockOpen>
              <Delete
                  :size="20"
                  @click.stop="deleteSnapshotShow(item.id, item.hotel_id)"
              ></Delete>
            </td>
          </template>
        </CDataTable>
      </CCol>
    </CRow>
  </CContainer>
</template>
<script lang="js">
import {labelModule} from "@/store/modules/labelModule";
import {hotelModule} from "@/store/modules/hotelModule";
import {CCol, CContainer, CRow, CSelect} from "@coreui/vue";

export default {
  name:"LabelCategories",
  data(){
    return{
      fields: [
        { label: "ID", key: "id", sorter: true, filter: true },
        { label: "Hotel", key: "hotel", sorter: true, filter: true },
        { label: "User", key: "user", sorter: true, filter: true },
        { label: "Options", key: "updated_at", sorter: false, filter: false },
      ],
      selectedHotel:null
    }
  },
  methods:{
    ...labelModule.mapActions(['getCategories']),
    ...hotelModule.mapActions(["getHotelList"]),
    async changeHotel(hotel_id) {
      this.selectedHotel = hotel_id;
      await this.getCategories(this.selectedHotel);
    },
  },
  components: {
    // Lock,
    // Delete,
    // CDataTable,
    CRow,
    CCol,
    CContainer,
    CSelect,
    // DeleteConfirmModal,
    // LockOpen,
  },
  computed:{
    ...hotelModule.mapGetters(['getHotelListData']),
    ...labelModule.mapState(['categories']),
    selectHotelList() {
      return this.getHotelListData.reduce((acc, item) => {
        let option = {
          label: item.name,
          value: item.id,
        };
        acc.push(option);
        return acc;
      }, []);
    },
  },
  async created(){
    if (!this.getHotelListData.length) {
      await this.getHotelList();
    }
    this.selectedHotel = this.getHotelListData[0].id;
    await this.getCategories(this.selectedHotel);
    console.log(this.categories);
  }
}
</script>

<style scoped lang="scss"></style>
